import React from "react"
import LayoutThird from "../../components/common/layout-third";
import SEO from "../../components/common/seo";
import SignatureNav from "../../components/common/signature-nav";
import Partners from "../../components/common/partners";
import Interested from "../../components/signature/interested";
import ModalContactSales from "../../components/modals/modal-contact-sales";
import ContactBox from "../../components/common/contact-box";
import {graphql} from "gatsby";
import ModalWhitePaper from "../../components/modals/modal-white-paper";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";

const FaqPage = ({data, pageContext}) => {
    const lang = pageContext.locale.path.default;
    const page = data.allStrapiSignatureFaqs.edges[0].node;
    const customFaqElements = (pClassName = '', ulClassName = '', liClassName = '') => {
        return {
            p: ({node, ...props}) => <p className={pClassName} {...props} />,
            ul: ({node, ...props}) => <ul className={ulClassName} {...props} />,
            li: ({node, ...props}) => <li className={liClassName} {...props} />,
        }
    }

    return (
        <LayoutThird pageContext={pageContext} cookies={data.cookies} favicon="favicon-sig.png">
            <SEO lang={lang} title={page.seo.title} image={page.seo.image.localFile.publicURL} description={page.seo.meta_description}/>

            <SignatureNav pageContext={pageContext}/>

            <section id="product-top" className="content f3 bg-grey border-bottom">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12 col-md-10 col-lg-9 text-center">
                            <br/>
                            <h4><span className="badge rounded-pill bg-tecalis mt-7 py-2 pr-3 pl-3 spacing text-white">{page.title}</span></h4>
                            <br/>
                        </div>

                        <div className="col-xl-12 mt-5 mb-10" data-aos="fade-up">
                            <div className="card accordion bg-white" id="featuresAccordion">
                                <div className="card-body">
                                    <div className="list-group list-group-flush">
                                        {page.faqs.map((faq, i) => {
                                            return <div className="list-group-item bg-white">
                                                <a className="d-flex align-items-center text-reset text-decoration-none" data-toggle="collapse" href={`#features-${i}`}
                                                   role="button"
                                                   aria-expanded="false" aria-controls="featuresOne">
                                                    <div className="mr-auto">
                                                        <span className="mr-4 ml-4  font-weight-bold text-tecalis spacing size-collapse">{faq.title}</span>
                                                    </div>

                                                    <span className="collapse-chevron text-muted ml-4">
                                                    <i className="fe fe-lg fe-chevron-down"/>
                                                </span>
                                                </a>

                                                <div className={i === 0 ? 'collapse show' : 'collapse'} id={`features-${i}`} data-parent="#featuresAccordion">
                                                    <div className="py-2 py-mb-6 spacing check-list">
                                                        <ReactMarkdown children={faq.description} rehypePlugins={[rehypeRaw]} components={customFaqElements('font-size-lg mt-4', 'spacing my-5 font-size-lg')}/>
                                                    </div>
                                                </div>
                                            </div>
                                        })}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Interested pageContext={pageContext}/>

            <Partners pageContext={pageContext}/>

            <ContactBox pageContext={pageContext} product="Signature"/>

            <ModalContactSales pageContext={pageContext} product="Signature"/>

            <ModalWhitePaper pageContext={pageContext}/>

            {/*<ModalSignatureDemo/>*/}
        </LayoutThird>
    )
}

export default FaqPage

export const faqPageQuery = graphql
    `query($lang: String) {
        allStrapiSignatureFaqs ( filter: { locale: { eq: $lang } } )
        {
            edges {
                node {
                    seo {
                        title
                        meta_description
                        image {
                            localFile {
                                publicURL
                            }
                        }
                    }
                    title
                    faqs {
                        order
                        title
                        description
                    }
                }
            }
        }
        cookies: markdownRemark(
            frontmatter: { 
                lang: {
                    eq: $lang 
                }
                name: {
                    eq: "cookies"
                }
            }) {
            frontmatter {
                cookiesStart
                cookiesEnd
                linkText        
            }
        }
}`;
